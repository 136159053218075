import React from 'react'

import JobListing from 'components/careers/JobListing'

const ProjectManager = () => (
  <JobListing
    name="Project Manager"
    salary="$60k – $75k"
    overview={
      <>
        <p>
          We're looking for a Project Manager (2-5 years experience) to join our
          growing team. You'll work alongside designers and developers on
          challenging client projects, and report directly to our Co-Founder.
        </p>
      </>
    }
    tasks={[
      'Gather and evaluate project requirements with us and our clients',
      'Participate in and supervise each stage of the project',
      'Create feature requirements and technical specs',
      'Act as a liaison between our team and our clients',
      'Ensure proper schedules and deadlines are respected',
      'Perform regular QA testing',
    ]}
    qualifications={[
      '2-5 years experience in a digital project management role',
      'Experience with agile methodologies (Scrum)',
      'Excellent communication and organizational skills',
      'Willingness to learn and grow',
      'Strong attention to detail and multi-tasking skills',
      'Bonus: bilingual (English and French)',
    ]}
  />
)

export default ProjectManager
